import React from 'react'
import ProductCard from './ProductCard'
import webdev from '../assets/web-dev-img.jpeg'
import database from "../assets/database.png";
import ML from "../assets/ML.jpg";
import compute from "../assets/compute.webp";
import storage from "../assets/storage.jpg";
import os from "../assets/os.jpg";
import networking from "../assets/networking.jpg";
import programming from "../assets/programming.avif";
import mdm from "../assets/mdm.svg"

export const product = [
  {
    courseName: "Master Data Management",
    productImage: mdm, // Assuming "webdev" is the filename for the web development image
    about: {
      title:
        "Master data management is a comprehensive method of enabling an enterprise to link all of its critical data to a common point of reference. When properly done, MDM improves data quality, while streamlining data sharing across personnel and departments. In addition, MDM can facilitate computing in multiple system architectures, platforms and applications. The most addressed master data domains are parties encompassing customer, supplier and employee roles, things as products and assets as well as location.",
      whatYouWillLearn: [
        "Have an overview of enterprise and master data.",
        "Be introduced to master data management.",
        "Be aware of the different classification and types of information.",
        "Understand the maturity model, the building blocks and the component layer model of MDM.",
        "Know the myths and reality about MDM.",
      ],
    },
    Curriculum: [
      "Introduction to Enterprise Data",
      "Characteristics of Enterprise Data",
      "Kinds of Business Data",
      "Definition of Master Data",
      "Business Intelligence",
      "Data Warehousing",
      "Enterprise Resource Planning",
    ],
    Outcome:
      "Upon completion, you'll be able to work with data and learn how to manage them.",
    Career: "Start a career as a MDM architect.",
    Pricing: "Rs. 20000 /-",
  },
  {
    courseName: "Web Development",
    productImage: webdev, // Assuming "webdev" is the filename for the web development image
    about: {
      title:
        "Learn the fundamentals of web development and build modern, responsive websites.",
      whatYouWillLearn: [],
    },
    Curriculum: [
      "HTML Fundamentals: Creating the structure of web pages",
      "CSS Styling: Designing visually appealing web pages",
      "JavaScript Basics: Adding interactivity and dynamic content",
      "Front-End Frameworks: Exploring popular frameworks like React and Vue.js",
      "Back-End Development: Introduction to Node.js and server-side programming",
      "Web APIs: Integrating with external services and APIs",
      "Database Integration: Connecting web applications to databases",
      "Responsive Web Design: Creating websites that adapt to different devices",
      "Web Performance Optimization: Speeding up website loading times",
      "Security Best Practices: Protecting web applications from common threats",
      "Version Control with Git: Managing codebase and collaboration",
      "Deployment: Launching web applications to the web",
    ],
    Outcome:
      "Upon completion, you'll be able to create fully functional web applications.",
    Career: "Start a career as a front-end or back-end web developer.",
    Pricing: "Rs. 20000 /-",
  },

  {
    courseName: "DBMS",
    productImage: database, // Assuming "database" is the filename for the DBMS image
    about: {
      title:
        "Master the concepts of Database Management Systems (DBMS) and SQL.",
      whatYouWillLearn: [],
    },
    Curriculum: [
      "Introduction to Database Concepts: Understanding data models and schemas",
      "Relational Database Management System (RDBMS): Fundamentals of RDBMS",
      "SQL Fundamentals: Querying and manipulating data with SQL",
      "Database Design: Creating efficient and normalized database structures",
      "Joins and Subqueries: Retrieving data from multiple tables",
      "Database Indexing: Optimizing query performance",
      "Database Administration: Managing and maintaining databases",
      "Database Security: Implementing access controls and encryption",
      "Backup and Recovery: Strategies for data backup and restoration",
      "NoSQL Databases: Exploring non-relational database systems",
      "Data Warehousing: Designing and building data warehouses",
      "Data Analytics: Analyzing data using SQL for business insights",
    ],
    Outcome:
      "Become proficient in handling databases and writing complex SQL queries.",
    Career: "Work as a database administrator or data analyst.",
    Pricing: "Rs. 20000 /-",
  },
  {
    courseName: "Machine Learning",
    productImage: ML, // Assuming "ML" is the filename for the Machine Learning image
    about: {
      title:
        "Explore the world of Machine Learning and its applications in various fields.",
      whatYouWillLearn: [],
    },
    Curriculum: [
      "Introduction to Machine Learning: Understanding ML concepts and terminology",
      "Supervised Learning: Regression and Classification algorithms",
      "Unsupervised Learning: Clustering and Dimensionality Reduction techniques",
      "Model Evaluation: Measuring the performance of machine learning models",
      "Model Selection and Hyperparameter Tuning",
      "Cross-Validation: Ensuring model generalization",
      "Feature Engineering: Extracting relevant features from data",
      "Ensemble Methods: Combining multiple models for better predictions",
      "Natural Language Processing (NLP): Analyzing and processing text data",
      "Image Recognition: Building image classification models",
      "Introduction to Deep Learning: Neural networks and deep learning frameworks",
      "Machine Learning in Production: Deploying ML models in real-world applications",
    ],
    Outcome:
      "Acquire the skills to develop and deploy machine learning models.",
    Career: "Pursue a career in machine learning engineering or data science.",
    Pricing: "Rs. 20000 /-",
  },
  {
    courseName: "Compute",
    productImage: compute, // Assuming "compute" is the filename for the Compute image
    about: {
      title:
        "Learn about cloud computing and how to utilize computing resources efficiently.",
      whatYouWillLearn: [],
    },
    Curriculum: [
      "Introduction to Cloud Computing: Understanding cloud models and services",
      "Cloud Service Providers: Overview of major cloud platforms",
      "Virtualization: Creating and managing virtual machines",
      "Containerization: Working with Docker and container orchestration",
      "Scalability and Load Balancing: Handling traffic spikes and distributing workloads",
      "Serverless Computing: Building applications without managing servers",
      "Cloud Security: Securing cloud-based applications and data",
      "Cost Optimization: Managing cloud costs and resource allocation",
      "High Availability and Disaster Recovery: Ensuring application resilience",
      "Cloud Monitoring and Performance Optimization",
      "Server Management: Configuring and managing cloud servers",
      "Cloud Governance: Implementing policies and best practices",
    ],
    Outcome:
      "Become proficient in deploying and managing cloud-based applications.",
    Career: "Start a career as a cloud engineer or cloud architect.",
    Pricing: "Rs. 20000 /-",
  },
  {
    courseName: "Storage",
    productImage: storage, // Assuming "storage" is the filename for the Storage image
    about: {
      title: "Explore various data storage technologies and best practices.",
      whatYouWillLearn: [],
    },
    Curriculum: [
      "Introduction to Data Storage: Understanding storage types and architectures",
      "Direct-Attached Storage (DAS) and Network-Attached Storage (NAS)",
      "Storage Area Network (SAN) and Fibre Channel technology",
      "RAID (Redundant Array of Independent Disks) configurations",
      "Cloud Storage Solutions: Working with object storage services",
      "Distributed File Systems: Managing large-scale data storage",
      "Data Backup and Recovery Strategies",
      "Data Replication and Disaster Recovery",
      "Data Deduplication and Compression: Optimizing storage efficiency",
      "Storage Performance Tuning: Enhancing data access speed",
      "Data Security in Storage: Encryption and access controls",
      "Storage Virtualization: Abstracting storage resources",
    ],
    Outcome: "Learn to design efficient and reliable data storage systems.",
    Career: "Work as a data storage specialist or cloud storage engineer.",
    Pricing: "Rs. 20000 /-",
  },
  {
    courseName: "Operating System",
    productImage: os, // Assuming "os" is the filename for the Operating System image
    about: {
      title:
        "Understand the core concepts and functionalities of operating systems.",
      whatYouWillLearn: [],
    },
    Curriculum: [
      "Introduction to Operating Systems: History and evolution",
      "Processes and Threads: Managing program execution",
      "Memory Management: Virtual memory and memory allocation",
      "File Systems: Organizing and accessing data on storage devices",
      "I/O Management: Managing input and output devices",
      "Scheduling Algorithms: Determining task priorities",
      "Concurrency and Synchronization: Handling multiple processes",
      "Deadlock Handling: Strategies to prevent and resolve deadlocks",
      "Device Drivers and Kernel Development",
      "Security and Protection Mechanisms",
      "Distributed Systems: Concepts and challenges",
      "Operating System Performance Analysis and Optimization",
    ],
    Outcome: "Develop a strong foundation in operating system concepts.",
    Career: "Start a career as a system administrator or OS kernel developer.",
    Pricing: "Rs. 20000 /-",
  },
  {
    courseName: "Networking",
    productImage: networking, // Assuming "networking" is the filename for the Networking image
    about: {
      title: "Dive into the world of computer networks and network protocols.",
      whatYouWillLearn: [],
    },
    Curriculum: [
      "Introduction to Networking: Understanding network models and topologies",
      "OSI and TCP/IP Models: Layers and their functions",
      "IP Addressing and Subnetting: Designing network addressing schemes",
      "Routing and Switching: Configuring network devices",
      "VLANs and Inter-VLAN Routing: Segmentation and traffic management",
      "Network Security: Firewalls, VPNs, and network protection",
      "Wireless Networking: Wi-Fi standards and configurations",
      "Network Troubleshooting and Diagnostics",
      "Quality of Service (QoS): Managing network performance",
      "Network Protocols: TCP, UDP, DNS, DHCP, etc.",
      "IPv6: Transitioning from IPv4 to IPv6",
      "Software-Defined Networking (SDN): Virtualizing network infrastructure",
    ],
    Outcome: "Become skilled in designing and managing computer networks.",
    Career: "Work as a network engineer or network administrator.",
    Pricing: "Rs. 20000 /-",
  },
  {
    courseName: "Programming",
    productImage: programming, // Assuming "programming" is the filename for the Programming image
    about: {
      title:"Learn the fundamentals of programming and various programming languages.",
      whatYouWillLearn: [],
    },
    Curriculum: [
      "Introduction to Programming Concepts: Variables, data types, and control flow",
      "Python Programming: Fundamentals and syntax",
      "Java Programming: Object-oriented programming and Java libraries",
      "C++ Programming: Memory management and advanced features",
      "Data Structures: Arrays, linked lists, stacks, queues, etc.",
      "Algorithms: Sorting, searching, recursion, etc.",
      "Functional Programming: Concepts and languages like Haskell or JavaScript",
      "Web Development with HTML, CSS, and JavaScript",
      "Version Control with Git: Managing codebase and collaboration",
      "Debugging and Error Handling: Troubleshooting code issues",
      "Software Development Life Cycle (SDLC): Development methodologies",
      "Code Optimization and Performance Analysis",
    ],
    Outcome:
      "Develop the skills to solve complex problems through programming.",
    Career: "Start a career as a software developer or programmer.",
    Pricing: "Rs. 20000 /-",
  },
];

function Card() {
  return (
    <div className="mainBodyCard">
      {product.map((ele, idx) => (
        <ProductCard product={ele} />
      ))}
    </div>
  );
}

export default Card