import './App.css';
import { BrowserRouter, Routes, Route} from "react-router-dom"
import Header from './components/Header';
import Card from "./components/Card";
import ProductPage from './components/ProductPage';


function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Header />

        <Routes>
          <Route path="/" element={<Card />} />
          <Route path=":id" element={<ProductPage />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
