import React, { useState } from "react";
import styled from "styled-components";
import { product } from "../components/Card";
import { useParams } from "react-router";
import AddIcon from "@mui/icons-material/Add";
import DoneIcon from "@mui/icons-material/Done";

function ProductPage() {
  const { id } = useParams();
  const currProduct = product.filter((ele, idx) => ele.courseName === id);
  const [activeTab, setActiveTab] = useState("About");
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <PageParent>
      <PageImage>
        <img src={currProduct[0].productImage} alt="hello" />
        <div>
          <h1>Classes Start Soon</h1>
        </div>
        <EnrollButton>Enroll</EnrollButton>
      </PageImage>
      <PageBody>
        <PageTitle>
          <PageTitleButton
            onClick={() => handleTabClick("About")}
            active={activeTab === "About"}
          >
            About
          </PageTitleButton>
          <PageTitleButton
            onClick={() => handleTabClick("Curriculum")}
            active={activeTab === "Curriculum"}
          >
            Curriculum
          </PageTitleButton>
          <PageTitleButton
            onClick={() => handleTabClick("Outcome")}
            active={activeTab === "Outcome"}
          >
            Outcome
          </PageTitleButton>
          <PageTitleButton
            onClick={() => handleTabClick("Career")}
            active={activeTab === "Career"}
          >
            Career
          </PageTitleButton>
          <PageTitleButton
            onClick={() => handleTabClick("Pricing")}
            active={activeTab === "Pricing"}
          >
            Pricing
          </PageTitleButton>
        </PageTitle>

        {activeTab === "About" && (
          <PageTitleAbout>
            <h3>{currProduct[0].about.title}</h3>
            <h2>What You Will Learn</h2>
            {currProduct[0].about.whatYouWillLearn.map((ele, idx) => (
              <LearnItem key={idx}>
                <DoneIcon />
                {ele}
              </LearnItem>
            ))}
          </PageTitleAbout>
        )}
        {activeTab === "Curriculum" && (
          <PageTitleCurriculum>
            {currProduct[0].Curriculum.map((ele, idx) => (
              <CurriculumItem key={idx}>
                <AddIcon />
                {ele}
              </CurriculumItem>
            ))}
          </PageTitleCurriculum>
        )}
        {activeTab === "Outcome" && (
          <PageTitleOutcome>
            <h3>{currProduct[0].Outcome}</h3>
          </PageTitleOutcome>
        )}
        {activeTab === "Career" && (
          <PageTitleCareer>
            <h3>{currProduct[0].Career}</h3>
          </PageTitleCareer>
        )}
        {activeTab === "Pricing" && (
          <PageTitlePricing>
            <h3>{currProduct[0].Pricing}</h3>
          </PageTitlePricing>
        )}
      </PageBody>
    </PageParent>
  );
}

const PageParent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100vw;
  height: 91vh;
  border: 2px solid black;
`;

const PageImage = styled.div`
  display: flex;
  flex-direction: column;
  width: 27vw;
  padding-top: 50px;
  border-right: 2px solid black;
`;

const EnrollButton = styled.button`
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;

  &:hover {
    background-color: #0056b3;
  }
`;

const PageBody = styled.div`
  width: 67vw;
`;

const PageTitle = styled.div`
  display: flex;
  justify-content: space-between;
  height: 10vh;
`;

const PageTitleButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20vw;
  border: 1px solid green;
  background-color: ${({ active }) => (active ? "blue" : "lightblue")};
  color: white;
  font-size: 16px;
  font-weight: bold;
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 10px;

  &:hover {
    background-color: blue;
  }
`;

const PageTitleAbout = styled.div`
  margin: 10px;
`;

const LearnItem = styled.div`
  display: flex;
  align-items: center;
  width: 45%;
  margin-bottom: 10px;

  & svg {
    margin-right: 5px;
  }
`;

const PageTitleCurriculum = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 20px;
`;

const CurriculumItem = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid grey;
  padding: 10px;
  margin: 10px;

  & svg {
    margin-right: 5px;
  }
`;

const PageTitleOutcome = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
`;

const PageTitleCareer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
`;

const PageTitlePricing = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
`;

export default ProductPage;
