import React, { useState } from "react";
import "../App.css"
import { Link } from "react-router-dom";

function ProductCard({ product }) {
  const [size, setSize] = useState("m");

  const handleSizeChange = (event) => {
    setSize(event.target.id);
  };

  return (
    <Link to={`/${product.courseName}`} style={{textDecoration:"none"}}>
      <div className="container">
        <div className="card">
          <div className="header">
            <div className="bg"></div>
            <img src={product.productImage} alt="" />
          </div>
          <div className="bottom">
            <div className="updata">
              <h1 className="name">{product.courseName}</h1>
              <div className="shoe-size">
                <label htmlFor="s">
                  <input
                    type="radio"
                    name="size"
                    id="s"
                    checked={size === "s"}
                    onChange={handleSizeChange}
                  />
                  <span>Beginner</span>
                </label>
                <label htmlFor="m">
                  <input
                    type="radio"
                    name="size"
                    id="m"
                    checked={size === "m"}
                    onChange={handleSizeChange}
                  />
                  <span>Intermediate</span>
                </label>
                <label htmlFor="l">
                  <input
                    type="radio"
                    name="size"
                    id="l"
                    checked={size === "l"}
                    onChange={handleSizeChange}
                  />
                  <span>Expert</span>
                </label>
              </div>
              <div className="description">
                Lorem ipsum dolor sit amsen queti consecter narl.
              </div>
            </div>
            <button className="buy">Enroll</button>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default ProductCard;
